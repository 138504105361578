import React from 'react';
import { Link } from 'gatsby'

import ROUTES from "constants/routes";
import "./notFound.scss"

const COLOR = "#212733";
const RECT_WIDTH = 15;

export default class NotFound extends React.Component {
  constructor(props) {
    super(props)

    this.errorRef = React.createRef();
    this.canvasRef = React.createRef();

    this.penIsDown = false
  }

  componentDidMount() {
    this.ctx = this.canvasRef.current.getContext('2d'); //set context to 2d
    this.canvasRef.current.height = window.innerHeight;
    this.canvasRef.current.width = window.innerWidth;

    this.resize();

    window.addEventListener('resize', this.resize);
    window.addEventListener('keypress', this.clear);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
    window.removeEventListener('keypress', this.clear);
  }

  resize = e => {
    this.canvasRef.current.height = window.innerHeight - this.errorRef.current.clientHeight;
    this.canvasRef.current.width = window.innerWidth;
    this.ctx.fillStyle = COLOR; //set background color
    this.ctx.fillRect(0,0,this.canvasRef.current.width,this.canvasRef.current.height); //fill background
    this.ctx.fillStyle = "white"; //set draw color
  }

  penDown = () => this.penIsDown = true

  mouseDraw = e => {
    if(this.penIsDown) {
      this.ctx.fillRect(
        e.clientX - RECT_WIDTH/2,
        e.clientY - this.errorRef.current.clientHeight-RECT_WIDTH/2,
        RECT_WIDTH,
        RECT_WIDTH,
      )
    }
  }

  penUp = () => this.penIsDown = false

  touchDraw = e => {
    this.ctx.fillRect(
      e.changedTouches[0].pageX - RECT_WIDTH/2,
      e.changedTouches[0].pageY - this.errorRef.current.clientHeight - RECT_WIDTH/2,
      RECT_WIDTH,
      RECT_WIDTH,
    )
  }

  //clear the canvas when user presses "c" key
  clear = e => {
    if(e.keyCode===67 || e.keyCode===99) {
      this.ctx.fillStyle= COLOR; //set background color
      this.ctx.fillRect(0,0,this.canvasRef.current.width,this.canvasRef.current.height); //color background
      this.ctx.fillStyle = "white"; //set draw color
    }
  }


  render() {
    return (
      <div id="errorContainer">
        <div id="error" ref={this.errorRef}>
          <h2>The page does not exist. You can:</h2>
          <h3>1) Click the button to go home</h3>
          <h3>2) Click below the white line to draw a picture :)</h3>
          <Link to={ROUTES.LANDING}><button>Home</button></Link>
        </div>
        <canvas
          ref={this.canvasRef}
          onMouseDown={e => {
            this.penDown()
            this.mouseDraw(e)
          }}
          onMouseMove={this.mouseDraw}
          onMouseUp={this.penUp}
          onTouchStart={e => {
            this.penDown()
            this.touchDraw(e)
          }}
          onTouchMove={this.touchDraw}
          onTouchEnd={this.penUp}
        ></canvas>
      </div>
    );
  }
}
